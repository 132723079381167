import React, {useEffect, useState} from 'react';
import {Col, Row, Menu, Card, Skeleton} from 'antd';
import categoryLogo from "../resource/category.png";
import http from "../framework/http";
import "./CategoryViewer.css";
import {useNavigate, useParams, Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import navPathStore from "../store/NavPath.Store.js";
import categoryStore from "../store/Category.Store.js";
import userInfoStore from "../store/UserInfo.Store";
import Decimal from "decimal.js";

function CategoryViewer() {
    const navigate = useNavigate();
    const [categoryTree, setCategoryTree] = useState();
    const [selectedCategories, setSelectedCategories] = useState();
    const [openedCategories, setOpenedCategories] = useState([]);
    const [cardViewContent, setCardViewContent] = useState([]);
    const params = useParams();
    // load categoryTree
    useEffect(() => {
        function buildCategoryTree(categoryMeta, parentId = 0) {
            const categoryTree = [];
            for (const item of categoryMeta.values()) {
                if (item.parentId === parentId) {
                    let categoryNode;
                    if (parentId === 0) {
                        categoryNode = {key: item.id, label: <span className="rootCategoryNode">{item.name}</span>};
                    } else {
                        categoryNode = {key: item.id, label: item.name};
                    }
                    const children = buildCategoryTree(categoryMeta, item.id);
                    if (children.length > 0) {
                        categoryNode.children = children;
                    }
                    categoryTree.push(categoryNode);
                }
            }
            return categoryTree;
        }

        if (categoryStore.getCategory()) {
            setCategoryTree(buildCategoryTree(categoryStore.getCategory()));
        }

    }, [categoryStore.category]);

    // load cards view according to categoryId carried in url
    useEffect(() => {
        if (!categoryStore.getCategory()) {
            return;
        }

        const categoryId = parseInt(params.categoryId);

        if (categoryId === 0) {
            updateTreeMenuState(categoryId);
            loadCategoryCards(categoryId);
            return;
        }

        if (isNaN(categoryId)) {
            navigate("/category/0")
            return;
        }

        const categoryItem = categoryStore.getCategory().get(categoryId)
        if (!categoryItem) {
            navigate("/category/0")
            return;
        }

        let hasChildCategory = false;
        for (const item of categoryStore.getCategory().values()) {
            if (item.parentId === categoryItem.id) {
                hasChildCategory = true;
                break;
            }
        }
        if (hasChildCategory) {
            updateTreeMenuState(categoryId);
            loadCategoryCards(categoryId);
        } else {
            updateTreeMenuState(categoryId);
            loadProductCards(categoryId);
        }

    }, [params, categoryTree]);

    const onMenuClick = (e) => {
        // setSelectedCategories(e.keyPath);
        // updateNavPath(e.keyPath);
        navigate("/category/" + e.key);
    };

    const onMenuOpenChange = (keys) => {
        let latestOpenKey;
        if (keys.length > openedCategories.length) {
            latestOpenKey = parseInt(keys.find((key) => openedCategories.indexOf(key) === -1));
        } else if (keys.length < openedCategories.length) {
            const latestClickedKey = openedCategories.find((key) => keys.indexOf(key) === -1);
            const categoryItem = categoryStore.getCategory().get(parseInt(latestClickedKey));
            latestOpenKey = categoryItem.parentId;
        } else {
            // theoretically equality situation not exist
            return;
        }
        // updateTreeMenuState(latestOpenKey);
        navigate("/category/" + latestOpenKey);
    };


    // set tree menu selected keys and opened keys, ensure that there is only one branch open at same time
    const updateTreeMenuState = (categoryId) => {
        let newOpenedCategories = [categoryId.toString()];
        let categoryItem = categoryStore.getCategory().get(categoryId);
        while (categoryItem && categoryItem.parentId !== 0) {
            categoryItem = categoryStore.getCategory().get(categoryItem.parentId);
            newOpenedCategories.push(categoryItem.id.toString());
        }
        setOpenedCategories(newOpenedCategories);
        setSelectedCategories(newOpenedCategories);
        updateNavPath(newOpenedCategories);
        scrollToContentTop();
    };

    const updateNavPath = (keys) => {
        let newNavPath = [];
        keys.map((key) => {
            const categoryItem = categoryStore.getCategory().get(parseInt(key));
            if (categoryItem) {
                newNavPath.unshift({title: <Link to={"/category/" + categoryItem.id}>{categoryItem.name}</Link>})
            }
        });
        newNavPath.unshift({title: <Link to="/" style={{fontWeight: "bold"}}>Home</Link>});
        navPathStore.setNavPath(newNavPath);
    };

    // load category cards view
    const loadCategoryCards = (categoryId) => {
        const filteredByParentId = Array.from((categoryStore.getCategory()).values()).filter((item) => item.parentId === categoryId)
        const newCardContent = [];
        let rowItems = [];
        let rowItemCount = 0;
        filteredByParentId.forEach((categoryItem) => {
            rowItems.push(
                <Col key={categoryItem.id} span={8}>
                    <Card className="categoryCard" hoverable onClick={() => {
                        // updateTreeMenuState(categoryItem.id);
                        navigate("/category/" + categoryItem.id);
                    }}>
                        <img src={categoryItem.picture ?
                            http.getUri() + categoryItem.picture :
                            http.getUri() + "images/default.png"} alt=""/>
                        <div className="title">{categoryItem.name}</div>
                    </Card>
                </Col>
            );
            if (++rowItemCount === 3) {
                newCardContent.push(<Row key={newCardContent.length} gutter={24}
                                         style={{marginBottom: 32}}>{rowItems}</Row>);
                rowItems = [];
                rowItemCount = 0;
            }
        });
        if (rowItems.length > 0) {
            newCardContent.push(<Row key={newCardContent.length} gutter={24}
                                     style={{marginBottom: 32}}>{rowItems}</Row>);
        }
        setCardViewContent(newCardContent);
    };

    // request product by categoryId then load product cards view
    const loadProductCards = async (categoryId) => {
        const res = await http.get("/product/category/" + categoryId);
        if (res.data && res.data.code === 200) {
            const newCardContent = [];
            let rowItems = [];
            let rowItemCount = 0;
            res.data.data.forEach((productItem) => {
                rowItems.push(
                    <Col key={productItem.id} span={8}>
                        <Link to={`/category/${params.categoryId}/product/${productItem.id}`}>
                            <Card className="productCard" hoverable>
                                <img src={productItem.cover ?
                                    http.getUri() + productItem.cover :
                                    http.getUri() + "images/default.png"} alt=""/>
                                <div className="title">{productItem.title}</div>
                                <p>{(() => {
                                    let showWholesalePrice = userInfoStore.activationStatus === 1 || userInfoStore.activationStatus === 2
                                    let index = showWholesalePrice ?
                                        productItem.wholesalePrice.indexOf("from") :
                                        productItem.retailPrice.indexOf("from");

                                    if (index >= 0) {
                                        return "from € " + (showWholesalePrice ?
                                                new Decimal(productItem.wholesalePrice.substring(5)) :
                                                new Decimal(productItem.retailPrice.substring(5))
                                        ).toFixed(2);
                                    } else {
                                        return "€ " + (showWholesalePrice ?
                                                new Decimal(productItem.wholesalePrice) :
                                                new Decimal(productItem.retailPrice)
                                        ).toFixed(2);
                                    }
                                })()}</p>
                            </Card>
                        </Link>
                    </Col>
                );
                if (++rowItemCount === 3) {
                    newCardContent.push(<Row key={newCardContent.length} gutter={24}
                                             style={{marginBottom: 32}}>{rowItems}</Row>);
                    rowItems = [];
                    rowItemCount = 0;
                }
            });
            if (rowItems.length > 0) {
                newCardContent.push(<Row key={newCardContent.length} gutter={24}
                                         style={{marginBottom: 32}}>{rowItems}</Row>);
            }
            setCardViewContent(newCardContent);

        }
    };

    const scrollToContentTop = () => {
        const headerHeight = 170;
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        if (scrollTop > 300) {
            window.scrollTo({
                top: headerHeight,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div>
            <Row wrap={false} gutter={72} style={{marginTop: 80, marginBottom: 80}}>
                <Col span={6}>
                    <div style={{width: 268}}>
                        <div style={{
                            height: 60,
                            background: "#0B53A3",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <img src={categoryLogo} alt=""/>
                        </div>
                        <div className="categoryMenu">
                            <Menu
                                mode="inline"
                                items={categoryTree}
                                selectedKeys={selectedCategories}
                                openKeys={openedCategories}
                                onOpenChange={onMenuOpenChange}
                                onSelect={onMenuClick}
                            />
                        </div>
                    </div>
                </Col>
                {/*Card View*/}
                <Col span={18} style={{width: 940}}>
                    {cardViewContent}
                </Col>
            </Row>
        </div>
    );
}

export default observer(CategoryViewer);
