import {unstable_HistoryRouter as HistoryRouter, Routes, Route, Navigate} from "react-router-dom";
import history from "./framework/history.js";
import React, {lazy, Suspense, useEffect, useState} from "react";

import Index from "./component/Index.js";
import Home from "./component/Home.js";
import NotFound from "./404.js";
import CategoryViewer from "./component/CategoryViewer";
// const CategoryViewer = lazy(() => import("./component/CategoryViewer.js"));
const ProductViewer = lazy(() => import("./component/ProductViewer"));
const Login = lazy(() => import("./component/Login.js"));
const RegisterPage = lazy(() => import("./component/Register.js"));
const UserCenter = lazy(() => import("./component/UserCenter.js"));
const UserCenterIndex = lazy(() => import("./component/UserCenterIndex.js"));
const ProfileManager = lazy(() => import("./component/ProfileManager.js"));
const AddressManager = lazy(() => import("./component/AddressManager.js"));
const OrderManager = lazy(() => import("./component/OrderManager.js"));
const ChangePassword = lazy(() => import("./component/ChangePassword.js"));
const OrderBuilder = lazy(() => import("./component/OrderBuilder.js"));
const CartManager = lazy(() => import("./component/CartManager.js"));
const SuccessPage = lazy(() => import("./component/SuccessPage.js"));
const FailedPage = lazy(() => import("./component/FailedPage.js"));
const AboutUs = lazy(() => import("./component/AboutUs.js"));
const RecycleBattery = lazy(() => import("./component/RecycleBattery.js"));
const ForgotPassword = lazy(() => import("./component/ForgotPassword.js"));
const ResetPassword = lazy(() => import("./component/ResetPassword.js"));
const TermsAndCondition = lazy(() => import("./component/TermsAndConditions.js"));

const MobileIndex = lazy(() => import("./componentMobile/Index.js"));
const MobileHome = lazy(() => import("./componentMobile/Home.js"));
const MobileCategoryViewer = lazy(() => import("./componentMobile/CategoryViewer.js"));
const MobileProductViewer = lazy(() => import("./componentMobile/ProductViewer.js"));
const MobileLogin = lazy(() => import("./componentMobile/Login.js"));
const MobileRegisterPage = lazy(() => import("./componentMobile/Register.js"));
const MobileCartManager = lazy(() => import("./componentMobile/CartManager.js"));
const MobileOrderBuilder = lazy(() => import("./componentMobile/OrderBuilder.js"));
const MobileUserCenter = lazy(() => import("./componentMobile/UserCenter.js"));
const MobileUserCenterIndex = lazy(() => import("./componentMobile/UserCenterIndex.js"));
const MobileProfileManager = lazy(() => import("./componentMobile/ProfileManager.js"));
const MobileAddressManager = lazy(() => import("./componentMobile/AddressManager.js"));
const MobileOrderManager = lazy(() => import("./componentMobile/OrderManager"));
const MobileChangePassword = lazy(() => import("./componentMobile/ChangePassword.js"));
const MobileSuccessPage = lazy(() => import("./componentMobile/SuccessPage.js"));
const MobileFailedPage = lazy(() => import("./componentMobile/FailedPage.js"));
const MobileAboutUs = lazy(() => import("./componentMobile/AboutUs.js"));
const MobileRecycleBattery = lazy(() => import("./componentMobile/RecycleBattery.js"));
const MobileForgotPassword = lazy(() => import("./componentMobile/ForgotPassword.js"));
const MobileResetPassword = lazy(() => import("./componentMobile/ResetPassword.js"));
const Mobile404 = lazy(() => import("./componentMobile/404.js"));
const MobileTermsAndCondition = lazy(() => import("./componentMobile/TermsAndConditions.js"));


function App() {
    const checkIsMobile = () => {
        // Check if running in browser environment
        if (typeof window === 'undefined') return false;

        return (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                window.navigator.userAgent
            )
            // Also check screen width for responsive design
            || window.innerWidth <= 768
        );
    };

    const [isMobile, setIsMobile] = useState(checkIsMobile());

    useEffect(() => {
        const handleResize = () => setIsMobile(checkIsMobile());
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div>
            <Suspense fallback={<div></div>}>{/*for now, not to show any loading tip*/}
                <HistoryRouter history={history}>
                    {isMobile ?
                        <Routes>
                            <Route path="/" element={<MobileIndex/>}>
                                <Route index element={<MobileHome/>}/>
                                <Route path="/category/:categoryId?" element={<MobileCategoryViewer/>}/>
                                <Route path="/category/:categoryId?/product/:productId" element={<MobileProductViewer/>}/>
                                <Route path="/product/:productId" element={<MobileProductViewer/>}/>
                                <Route path="/login" element={<MobileLogin/>}/>
                                <Route path="/register" element={<MobileRegisterPage/>}/>
                                <Route path="/cart" element={<MobileCartManager/>}/>
                                <Route path="/orderPreview" element={<MobileOrderBuilder/>}/>
                                <Route path="/userCenter" element={<MobileUserCenter/>}>
                                    <Route index element={<MobileUserCenterIndex/>}/>
                                    <Route path="profileManager" element={<MobileProfileManager/>}/>
                                    <Route path="addressManager" element={<MobileAddressManager/>}/>
                                    <Route path="orderManager" element={<MobileOrderManager/>}/>
                                    <Route path="changePassword" element={<MobileChangePassword/>}/>
                                </Route>
                                <Route path="/orderSuccess" element={<MobileSuccessPage msg="Order"/>}/>
                                <Route path="/paymentSuccess" element={<MobileSuccessPage msg="Payment"/>}/>
                                <Route path="/paymentFailed" element={<MobileFailedPage/>}/>
                                <Route path="/aboutUs" element={<MobileAboutUs/>}/>
                                <Route path="/recycleBattery" element={<MobileRecycleBattery/>}/>
                                <Route path="/forgotPassword" element={<MobileForgotPassword/>}/>
                                <Route path="/resetPassword/:token" element={<MobileResetPassword/>}/>
                                <Route path="/termsAndConditions" element={<MobileTermsAndCondition/>}/>
                            </Route>
                            <Route path="/*" element={<Mobile404/>}/>
                        </Routes>
                        :
                        <Routes>
                            <Route path="/" element={<Index/>}>
                                <Route index element={<Home/>}/>
                                <Route path="/category/:categoryId?" element={<CategoryViewer/>}/>
                                <Route path="/category/:categoryId?/product/:productId" element={<ProductViewer/>}/>
                                <Route path="/product/:productId" element={<ProductViewer/>}/>
                                <Route path="/login" element={<Login/>}/>
                                <Route path="/register" element={<RegisterPage/>}/>
                                <Route path="/cart" element={<CartManager/>}/>
                                <Route path="/orderPreview" element={<OrderBuilder/>}/>
                                <Route path="/userCenter" element={<UserCenter/>}>
                                    <Route index element={<UserCenterIndex/>}/>
                                    <Route path="profileManager" element={<ProfileManager/>}/>
                                    <Route path="addressManager" element={<AddressManager/>}/>
                                    <Route path="orderManager" element={<OrderManager/>}/>
                                    <Route path="changePassword" element={<ChangePassword/>}/>
                                </Route>
                                <Route path="/orderSuccess" element={<SuccessPage msg="Order"/>}/>
                                <Route path="/paymentSuccess" element={<SuccessPage msg="Payment"/>}/>
                                <Route path="/paymentFailed" element={<FailedPage/>}/>
                                <Route path="/aboutUs" element={<AboutUs/>}/>
                                <Route path="/recycleBattery" element={<RecycleBattery/>}/>
                                <Route path="/forgotPassword" element={<ForgotPassword/>}/>
                                <Route path="/resetPassword/:token" element={<ResetPassword/>}/>
                                <Route path="/termsAndConditions" element={<TermsAndCondition/>}/>
                            </Route>
                            <Route path="/*" element={<NotFound/>}/>
                        </Routes>
                    }
                </HistoryRouter>
            </Suspense>
        </div>
    );
}

export default App;
