import React, {useEffect, useState} from 'react';
import {Carousel} from 'antd';
import advertisementImg from "../resource/ad.png";
import http from "../framework/http";

function Home() {
    const [carouselContent, setCarouselContent] = useState();
    useEffect(() => {
        async function loadCarousel() {
            const res = await http.get("/slide/list");
            if (res.data && res.data.code === 200) {
                const slides = res.data.data;
                let imgArr = [];
                slides.map((slide) => {
                    imgArr.push(<img key={slide.id} src={http.getUri() + slide.picture} alt=""/>)
                });
                setCarouselContent(imgArr);
            }
        }
        loadCarousel();
    }, []);


    return (
        <div>
            <img src={advertisementImg} alt=""/>
            <Carousel>
                <Carousel autoplay>
                    {carouselContent}
                </Carousel>
            </Carousel>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9530.68141306336!2d-6.3599224!3d53.3312554!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5783e2c0eea7a86d!2sDublin%20Computer%20Wholesale%20LTD!5e0!3m2!1sen!2sie!5m2!1sen!2sie"
                width="100%" height="450" style={{border: 0}} allowFullScreen=""/>
        </div>
    );
}

export default Home;
